/* eslint-disable */
// @ts-ignore
import axios,{AxiosInstance,AxiosRequestConfig} from 'axios';
import { ElMessage } from 'element-plus'
import router from '../router';
// @ts-ignore
import CONFIG from '../../public/serverConfig'
// import commMethods from "../assets/js/common"
// import vue_ from '../main'
// import store from '../store'
// axios.defaults.withCredentials = true;//让ajax携带cookie
const request:AxiosInstance = axios.create({
  baseURL: CONFIG.baseURL, // api 的 base_url
  timeout: 5000000 // request timeout
})
// 添加请求拦截器
// request.defaults.headers.common!["Authorization"]=commMethods.getStorage('Authorization')||''
request.interceptors.request.use((config:any) => {
  //请求统一增加请求头
  config.headers!['Content-Type'] = 'application/json';
  // config.headers!['Content-Type'] = 'application/x-www-form-urlencoded'
  config.headers={
    // Authorization:commMethods.getStorage('Authorization')||''
  }
  // config?.headers?.common!["Authorization"]=commMethods.getStorage('Authorization')||''
  return config;
})
//添加响应拦截器
request.interceptors.response.use((res:any) => {
  console.log('???',res)
  if (res.config.responseType == 'arraybuffer') {
    console.log(res.data,res.headers,res.data.code)
    console.log(res)
    if(res.headers.uploadstatus=='S'||res.headers.exportstatus=='S'){
      return Promise.resolve(res.data)
    }else{
      return Promise.reject(res.data)
    }
    // return Promise.resolve(res.data)
    // let info=JSON.parse(res.headers.fileinfo);
    // console.log(info)
    // //文件异常时
    // if(info.code=='50'){
    //   return Promise.reject(Object.assign(res.data,info))
    // }
    // if(res.data.code=="S507"){
    //   return Promise.resolve(res.data)
    // }else{
    //   return Promise.reject(res.data)
    // }
    // if(res.data.code=="S507")
    // return Promise.resolve(Object.assign(res.data,res.headers))
    // else{
    //   return Promise.reject(res.data)
    // }
  }
  //成功返回
  if(res.data.success){
    // ElMessage.success(res.data.message)
    return Promise.resolve(res.data)
  }else{
    return Promise.reject(res.data)
  }
  // if (['success','S505','S506','S507','U200','D003','D005'].indexOf(res.data.code)>-1) {
  //   // if(['S505','S506','S507'].indexOf(res.data.code)>-1){
  //   //   ElMessage.success(res.data.msg)
  //   // }
  //
  //   // 返回data
  //   return Promise.resolve(res.data)
  // } else {
  //   if (['U204','U205'].indexOf(res.data.code)>-1) {
  //     localStorage.clear();
  //     router.push('/login');
  //   }
  //   // //没有权限
  //   // if (res.data.code == '3') {
  //   //   // store.state.refreshMenu = true;
  //   // }
  //   // ElMessage.error(res.data.msg);
  //   return Promise.reject(res.data)
  // }
  // return response.data
}, (error:any) => {
  //失败返回，方法返回一个带有拒绝原因reason参数的Promise对象。
  console.log(error)
  return Promise.reject(error)
})


function get(url:string, data?:any, responseType?:any) {
  return request.get(url, {
    params: data,
    responseType:responseType
  })
}

function post(url:string, data?: any, responseType?:any) {
  return request.post(url, data,{responseType:responseType})
}
export default {
  get, post
}