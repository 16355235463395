<template>
  <router-view/>
</template>

<style lang="scss">
body{
  margin: 0;
  background: #141727!important;
  font-family: "Exo 2";
}
*{
  box-sizing: border-box;//会把borders和padding全都包含在定义的宽高里面
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.flex-n{
  display: flex;
}
.fac{
  @extend .flex-n;
  align-items: center;
}
.fjb{
  @extend .flex-n;
  justify-content: space-between;
}
.fjc{
  @extend .flex-n;
  justify-content: center;
}
.fac-jc{
  @extend .fac;
  justify-content: center;
}
.fac-jb{
  @extend .fac;
  justify-content: space-between;
}

</style>
